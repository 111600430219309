import request from '@/utils/request'
// 搜索
export const chatGroup = (data) => {
  return request('customerservice/search_user', 'get', data)
}
// 创建聊天会话
export const chatGroupUser = (data) => {
  return request('customerservice/chat_group_user', 'post', data)
}
// 一对一添加聊天记录
export const chatLogSingle = (data) => {
  return request('customerservice/chat_log_single', 'post', data)
}
// 群聊添加聊天记录
export const chatLogGroup = (data) => {
  return request('customerservice/chat_log_group', 'post', data)
}
// 分页查询聊天记录
export const chatLogPage = (data) => {
  return request('customerservice/chat_log_page', 'get', data)
}
// 聊天列表
export const chatGroupPage = (data) => {
  return request('customerservice/chat_group_page', 'get', data)
}
// 验证码
export const code = (data) => {
  return request('customerservice/captcha', 'get', data)
}
// 客服登录
export const login = (data) => {
  return request('customerservice/login', 'post', data)
}
// 查看群成员列表
export const groupMembers = (data) => {
  return request('customerservice/group_members', 'get', data)
}
// 会话操作
export const operateGroup = (data) => {
  return request('customerservice/operate_group', 'post', data)
}
// 邀请加入群聊
export const joinGroup = (data) => {
  return request('customerservice/join_group', 'post', data)
}
// 创建群聊
export const createGroup = (data) => {
  return request('customerservice/create_group', 'post', data)
}
// 消息已读
export const operateMsg = (data) => {
  return request('customerservice/operate_msg', 'post', data)
}
// 修改群信息
export const editGroup = (data) => {
  return request('customerservice/edit_group', 'post', data)
}
// 解散群聊
export const exitGroup = (data) => {
  return request('customerservice/exit_group', 'post', data)
}
// 获取在线客服
export const getOnlineChatService = (data) => {
  return request('customerservice/get_online_chat_service', 'get', data)
}
// 修改备注
export const editSingle = (data) => {
  return request('customerservice/edit_single', 'post', data)
}
